import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'

const Error = ({ data }) => {
  const heroImage = data.heroImage
  return (
    <>
      <Layout>
        <div id="sub-page">
          <Hero
            class="intro-100"
            image={heroImage.childImageSharp.gatsbyImageData}
            alt="Firetide 404 - page not found!"
            title="Error 404"
            subtitle="Oops, we couldn't find the page you're looking for ...please go to our home page by clicking the button below."
            ctaslug="/"
            ctatext="Take me there"
            type="404"
          />
        </div>

        <main></main>
      </Layout>
    </>
  )
}
export default Error

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="Error 404"
      description="Error 404 - Something must have gone wrong for you to get here!  Please head back to the MEMEO home page, use the search page, or try the sitemap."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    heroImage: file(name: { eq: "404-not-found" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`